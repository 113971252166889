<template>
   <div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-6 col-md-4 col-xl-5 px-1 mb-2">
                     <label><i class="far fa-calendar-check color-theme font-12 me-1"></i> Data Inicial</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataInicial"></datePicker>
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 px-1 mb-2">
                     <label><i class="far fa-calendar-times color-theme font-12 me-1"></i> Data final</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataFinal"></datePicker>
                  </div>
                  <div class="col-md-4 col-xl-2 px-1 mb-2 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarVendas">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 p-0" v-if="resultado != null">
         <div class="card mb-1">
            <div class="card-body p-3">
               <div class="row m-0">
                  <div class="col-12 p-0 mb-1">
                     <div class="row text-center mb-1 weight-700 m-0">
                        <div class="col px-1">
                           <span class="limitador">
                              <i class="far fa-stream me-2 font-13 color-theme"></i>Status
                           </span>
                        </div>
                        <div class="col px-1 d-none d-md-inline" title="Valor PagHiper">
                           <span class="limitador">
                              <i class="far fa-money-bill-wave-alt me-2 font-13 color-theme"></i>PagHiper
                           </span>
                        </div>
                        <div class="col px-1 d-none d-sm-inline" title="Vendas MercadoPago">
                           <span class="limitador">
                              <i class="far fa-credit-card me-2 font-13 color-theme"></i>MercadoPago
                           </span>
                        </div>
                        <div class="col px-1 d-none d-sm-inline" title="Vendas PayPal">
                           <span class="limitador">
                              <i class="fab fa-paypal me-2 font-13 color-theme"></i>PayPal
                           </span>
                        </div>
                        <div class="col px-1 d-none d-lg-inline" title="Vendas In-game">
                           <span class="limitador">
                              <i class="far fa-gamepad-alt me-2 font-13 color-theme"></i>In-game
                           </span>
                        </div>
                        <div class="col px-1">
                           <span class="limitador">
                              <i class="fas fa-equals me-2 font-13 color-theme"></i>Total
                           </span>
                        </div>
                     </div>
                  </div>

                  <ecommerce v-for="(status, index) in resultado.status" :key="index" :status="status" :index="index" />

                  <div class="col-12 mt-2 p-0">
                     <div class="row text-center color-theme weight-700 m-0">
                        <div class="col px-1">
                           <span class="limitador">Total</span>
                        </div>
                        <div class="col px-1 d-none d-md-inline">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(resultado.totalPagHiper).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                        <div class="col px-1 d-none d-sm-inline">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(resultado.totalMercadoPago).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                        <div class="col px-1 d-none d-sm-inline">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(resultado.totalPayPal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                        <div class="col px-1 d-none d-lg-inline">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(resultado.totalInGame).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                        <div class="col px-1">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(resultado.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ecommerce from '@/components/admin/vendas/Ecommerce.vue'

export default {
	name: 'Vendas',
   data: function () {
      return {
         pesquisa: {'dataInicial': '', 'dataFinal': ''},
         ptBR: ptBR,
         resultado: null
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      ecommerce
   },
   methods: {
      buscarVendas : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminsales/getSales',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params: {
               dataInicial: this.pesquisa.dataInicial.getDate() +'-'+ (this.pesquisa.dataInicial.getMonth() + 1) +'-'+ this.pesquisa.dataInicial.getFullYear(),
               dataFinal: this.pesquisa.dataFinal.getDate() +'-'+ (this.pesquisa.dataFinal.getMonth() + 1) +'-'+ this.pesquisa.dataFinal.getFullYear()
            }
         }).then(response => {
            this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      this.pesquisa.dataInicial = new Date()
      this.pesquisa.dataFinal = new Date()
   }
}

</script>