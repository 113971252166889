<template>
   <div class="col-12 p-0">
      <div class="card hover mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body py-2 px-0">
            <div class="row text-center my-1 mx-0">
               <div class="col px-1">
                  <span class="limitador">{{ status.status }}</span>
               </div>
               <div class="col px-1 d-none d-md-inline" title="Valor PagHiper">
                  <span class="limitador">
                     <small class="font-10">R$</small> {{ parseFloat(status.valorPagHiper).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col px-1 d-none d-sm-inline" title="Vendas MercadoPago">
                  <span class="limitador">
                     <small class="font-10">R$</small> {{ parseFloat(status.valorMercadoPago).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col px-1 d-none d-sm-inline" title="Vendas PayPal">
                  <span class="limitador">
                     <small class="font-10">R$</small> {{ parseFloat(status.valorPayPal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col px-1 d-none d-lg-inline" title="Vendas In-game">
                  <span class="limitador">
                     <small class="font-10">R$</small> {{ parseFloat(status.valorInGame).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col px-1">
                  <span class="limitador">
                     <small class="font-10">R$</small> {{ parseFloat(status.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Ecommerce',
   props: ['status', 'index']
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row>* {
   padding: 0;
}

</style>